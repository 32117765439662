import { Nav } from "react-bootstrap";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import TaskGenCard from "../../components/taskGenerationComponents/TaskGenCard";
import { forayRoutes } from "../../routes/routes";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import FillTaskInformation from "../../components/taskGenerationComponents/FillTaskInformation";
import AddCategoryModal from "../../components/modals/AddCategoryModal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TaskGenerationListing from "../../components/taskGenerationComponents/TaskGenerationListing";
import { useCategories } from "../../hooks/useCateogories";
import { ICategory, Section } from "../../types/ICategories.interface";
import AddSectionModal from "../../components/modals/AddSectionModal";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { useDeleteCategory } from "../../hooks/useDeleteCategory";
import { useDeleteSection } from "../../hooks/useDeleteSection";

const TaskGeneration = () => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [modalType, setModalType] = useState("")
  const [selectedCard, setSelectedCard] = useState<ICategory>();
  const [selectedSection, setSelectedSection] = useState<Section>();
  const [showSectionOptions, setShowSectionOptions] = useState(false);

  const sectionOptionsRef = useRef<HTMLDivElement>(null);
  useOutsideClick(sectionOptionsRef, () => { setShowSectionOptions(false) });

  const { data: Categories } = useCategories();
  const { mutate: deleteSection, isSuccess: isDeleteSectionSuccess } = useDeleteSection()

  const handleDeleteSection = (categoryId: string, sectionId: string) => {
    if (categoryId && sectionId) {
      deleteSection({ categoryId, sectionId })
    } else {
      console.log("Category id or section id is not available")
    }
  }
  const openCategoryModal = (modalType: string) => {
    setModalType(modalType)
    setIsCategoryModalOpen(true)
  }

  const closeCategoryModal = useCallback(() => {
    setSelectedCard(undefined)
    setModalType("")
    setIsCategoryModalOpen(false)
  }, [])

  const openSectionModal = (modalType: string) => {
    setModalType(modalType)
    setIsSectionModalOpen(true)
  }

  const closeSectionModal = () => {
    setIsSectionModalOpen(false)
    setModalType("")
  }

  const breadcrumbItems = [
    { label: "Task Generation", link: forayRoutes.foray_screen },
    { label: "Starts", isCurrent: true },
  ];

  const handleSelectedCard = (selectedCardId: string) => {
    const selectedCategory = Categories?.data?.find(
      (category) => category._id === selectedCardId
    );
    setSelectedCard(selectedCategory);
  };

  const handleSelectedSection = (section: Section) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    if (isDeleteSectionSuccess) {
      setSelectedSection(undefined)
    }
  }, [isDeleteSectionSuccess])
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <div className="flex items-center justify-between mb-5 mt-10">
            <h2 className="text-xl text-secondary font-semibold">
              Task Generation
            </h2>
            <button className="btnPrimary max-w-[192px]" onClick={() => { openCategoryModal("add") }}>
              Add Category <HiOutlinePlusSmall className="w-5 h-5" />
            </button>
            <AddCategoryModal isOpen={isCategoryModalOpen} onClose={closeCategoryModal} modalType={modalType} selectedCard={selectedCard as ICategory} />
          </div>
          <TaskGenCard
            items={Categories?.data}
            handleSelected={handleSelectedCard}
            openModal={openCategoryModal}
            selectedCard={selectedCard as ICategory}
          />
          <div className="flex items-end gap-2 my-6">
            <Nav
              activeKey="/0"
              variant="pills"
              onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
              className="flex items-center w-full flex-wrap"
            >
              {Categories?.data &&
                Categories.data
                  .find((item) => item._id === selectedCard?._id)
                  ?.sections.map((section) => (
                    <div
                      onClick={() => handleSelectedSection(section)}
                      key={section._id}
                      className=""
                    >
                      <div className={`inline-flex whitespace-nowrap items-center px-3 pb-2 relative ${selectedSection?._id === section._id ? "text-primary text-xl font-bold border-primary" : "text-lg text-secondaryVariant border-secondaryVariant2"} border-b-[2px]  gap-1 hover:border-primary hover:text-primary`}>
                        {section.title}
                        <button onClick={() => { setShowSectionOptions(true) }}>
                          <PiDotsThreeVerticalBold className="w-6 h-6" />
                        </button>
                        {showSectionOptions && selectedSection?._id === section._id && (
                          <div ref={sectionOptionsRef} className="bg-white p-2 top-[100%_!important] rounded-lg shadow-lg min-w-[137px] left-auto right-0 flex flex-col gap-1 absolute">
                            <button
                              className="hover:text-primary py-1 px-3 flex items-center gap-3 text-secondaryVariant text-sm font-medium"
                              onClick={() => openSectionModal("edit")}
                            >
                              <FiEdit3 className="text-md" />
                              Edit
                            </button>
                            <button
                              className="hover:text-primary py-1 px-3 flex items-center gap-3 text-secondaryVariant text-sm font-medium"
                              onClick={() => handleDeleteSection(selectedCard?._id as string, selectedSection?._id as string)}
                            >
                              <HiOutlineTrash className="text-md" />
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
            </Nav>
            <button className="bg-primary p-1.5 rounded text-white" onClick={() => openSectionModal("add")}>
              <HiOutlinePlusSmall className="w-7 h-7" />
            </button>
          </div>
          <FillTaskInformation selectedSection={selectedSection as Section} />
          <TaskGenerationListing />
          <AddSectionModal isOpen={isSectionModalOpen} onClose={closeSectionModal} modalType={modalType} selectedCard={selectedCard as ICategory} selectedSection={selectedSection as Section} />
        </div >
      </DashboardWrapper >
    </>
  );
};

export default TaskGeneration;
