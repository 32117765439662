import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { showSuccessToast } from "../../shared/utils/toast";

export const useDeleteCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (categoryId: string) =>
            taskGenerationSvc.deleteCategory(categoryId),
        onError: (err) => {
            console.error("error:", err);
        },
        onSuccess: () => {
            showSuccessToast("Category deleted successfully")
            queryClient.invalidateQueries({
                queryKey: [TaskGenerationQueryKeyString.Category],
            });
        },
    });
};
