import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { ICategory, Section } from "../../types/ICategories.interface";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { useAddSection } from "../../hooks/useAddSection";
import { useUpdateSection } from "../../hooks/useUpdateSection";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: string;
  selectedCard: ICategory
  selectedSection: Section
}

const AddSectionModal: React.FC<ModalProps> = ({ isOpen, onClose, modalType, selectedCard, selectedSection }) => {
  const [sectionName, setSectionName] = useState("")
  const sectionModalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(sectionModalRef, onClose);

  const { isSuccess: addSectionSuccess, mutate: addSection } = useAddSection()
  const { isSuccess: updateSectionSuccess, mutate: updateSection } = useUpdateSection()

  const handleSaveClick = () => {
    if (!sectionName || !selectedCard?._id) {
      console.error("Section name or category id is missing.");
      return;
    }

    const reqBody = { title: sectionName }

    if (modalType === "add") {
      addSection({ categoryId: selectedCard?._id, reqBody });
    } else if (modalType === "edit" && selectedCard?._id && selectedSection?._id) {
      updateSection({
        categoryId: selectedCard._id,
        sectionId: selectedSection._id,
        reqBody,
      });
    }
  };

  useEffect(() => {
    if (addSectionSuccess || updateSectionSuccess) {
      onClose();
      setSectionName("");
    }
  }, [addSectionSuccess, updateSectionSuccess])

  useEffect(() => {
    if (modalType === "edit" && selectedSection) {
      setSectionName(selectedSection?.title);
    } else {
      setSectionName("");
    }
  }, [modalType, selectedSection]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[99999]">
      <div className="bg-white px-6 py-4 rounded-lg shadow-lg w-[464px]">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-secondary">
            {modalType === "add" ? "Add" : "Edit"} Section
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div>

        <div className="py-4">
          <label
            htmlFor=""
            className="text-[10px] text-secondaryVariant mb-0.5"
          >
            Section Name
          </label>
          <input
            type="text"
            placeholder="Name"
            className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
            value={sectionName || ""}
            onChange={(e) => setSectionName(e.target.value)}
          />
        </div>

        <div className="flex items-center justify-end gap-4">
          <button className="text-sm font-medium text-secondaryVariant2" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btnPrimary max-w-[81px] flex justify-center"
            onClick={handleSaveClick}
          >
            {modalType === "add" ? "Add" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSectionModal;
