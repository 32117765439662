import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { showSuccessToast } from "../../shared/utils/toast";
import { ISectionUpdate } from "../types/ITaskGeneration.interface";

export const useAddSection = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({
            categoryId,
            reqBody,
        }: {
            categoryId: string;
            reqBody: ISectionUpdate;
        }) => taskGenerationSvc.addSectionToCategory(categoryId, reqBody),
        onError: (err) => {
            console.error("error:", err);
        },
        onSuccess: () => {
            showSuccessToast("Section created successfully");
            queryClient.invalidateQueries({
                queryKey: [TaskGenerationQueryKeyString.Category],
            });
        },
    });
};