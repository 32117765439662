import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { showSuccessToast } from "../../shared/utils/toast";

export const useDeleteSection = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ categoryId, sectionId }: { categoryId: string; sectionId: string }) =>
            taskGenerationSvc.deleteSection(categoryId, sectionId),
        onError: (err) => {
            console.error("error:", err);
        },
        onSuccess: () => {
            showSuccessToast("Section deleted successfully")
            queryClient.invalidateQueries({
                queryKey: [TaskGenerationQueryKeyString.Category],
            });
        },
    });
};